.employee-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .employee-container {
        padding-top: 150px;
    }
 }